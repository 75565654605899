export function getFieldError(field: string): string | null {
  let $field: any = null

  if (Array.isArray(field)) {
    $field = field.reduce((prev: any, current: any) => {
      return prev[current]
      // @ts-ignore
    }, this.$v.form)
  }
  else {
    // @ts-ignore
    $field = this.$v.form[field]
  }

  if ($field && $field.$dirty) {
    // @ts-ignore
    if (($field.required === false)) {
      return 'Поле обязательно для заполнения'
    }

    // @ts-ignore
    if (($field.minLength === false)) {
      return 'Слишком короткое значение'
    }
  }

  return null
}
