
















































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import AddressSelect from '@/components/Controls/AddressSelect.vue'

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty',
    ]),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      brand_id: {},
      type: { required },
      category_id: { required },
      chief_email: { email },
      chief_name: {},
      chief_phone: {},
      city_id: {},
      franchise_contract_upload_id: {},
      inn: { required },
      inn_upload_id: {},
      kpp: {},
      kpp_upload_id: {},
      legal_address: {},
      logo_upload_id: {},
      name: { required },
      parent_id: {},
      trade_type: {},
      contacts: [],
      item_sum: {},
      allow_accural_auto_start: {},
      allow_accural_manual_start: {},
      can_request_product_feedback: {},
      can_request_receipt_feedback: {},

      requisites_ogrn: {},
      requisites_ogrn_date: {},
      requisites_okpo: {},
      requisites_payment_account: {},
      requisites_correspondent_account: {},
      requisites_bik: {},
      requisites_bank_name: {},
      requisites_actual_address: {},
      requisites_tax_type_id: {},
    }

    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
    AddressSelect,
  },
})
export default class MerchantForm extends Vue {

  public merchants_types: any = {
    producer: { name: 'Производитель' },
    merchant: { name: 'Магазин' },
    producer_merchant: { name: 'Производитель-магазин' },
  }

  get merchantsTypesList() {
    return Object.keys(this.merchants_types).map((k) => {
      const t: any = this.merchants_types[k]
      return {
        name: t.name,
        value: k,
      }
    })
  }

  get isNew() {
    return !Number.isInteger(Number(this.$route.params.id))
  }

  get titleStack() {
    return [
      'Организации', (this.isNew ? 'Новая организация' : 'Редактирование организации'),
    ]
  }

  public changeValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  public getContact(type: string) {
    // @ts-ignore
    if (!this.form.contacts) {
      return {
        value: '',
        is_public: true,
      }
    }
    // @ts-ignore
    for (const contact of this.form.contacts) {
      if (contact.type === type) {
        return {
          value: contact.value,
          is_public: contact.is_public,
        }
      }
    }
    return {
      value: '',
      is_public: true,
    }
  }

  public changeContacts(field: string, value: any, is_public: any) {
    this.$store.dispatch(`${STORE_KEY}/changeContactValue`, {
      field, newValue: value, newIsPublic: is_public,
    })
    // @ts-ignore
    this.$v.form.contacts.$touch()
  }

  public submitObject() {
    this.$v.form.$touch()

    if (!this.$v.form.$error) {
      this.$store.dispatch(`${STORE_KEY}/saveObject`, this.$route.params.id)
        .then(() => {
          this.$buefy.snackbar.open(`Данные успешно сохранены`)

          if ('return_to_observe' in this.$route.params) {
            // @ts-ignore
            this.$router.push({ name: 'merchant-observe', params: { id: this.form.id, active_tab: 'main' } })
          }
          else {
            this.$router.push({ name: 'merchant-list' })
          }
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            type: 'is-warning',
            message: `При сохраненит возникли проблемы. Попробуйте позже.`,
          })
        })
    }
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    // Проверяем, лежит ли в параметрах уже заполненная форма (выбрал поле inn)
    if ('inn' in this.$route.params) {
      this.$store.dispatch(`${STORE_KEY}/loadPredefinedForm`, {
        form_data: this.$route.params,
      })
    }
    else {
      this.$store.dispatch(`${STORE_KEY}/initState`, {
        id: this.$route.params.id,
        predefined: this.$route.query,
      })
    }
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
